<template>
  <div>
    GEO
  </div>
</template>

<script>

export default {
  name: "Layout",

  data: () => ({

    id: 28,

    templ_ready: false,
    templ: {},

  }),

  created() {







    if (this.$store.getters.isAuthenticated){

      fetch(this.$store.state.auth.path+"/layout/"+this.id+"/sp", {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+ this.$store.state.auth.token,
          'Content-Type': 'application/json'
        }
      })
          .then(response => response.json())
          .then((data) => {
            console.log(data);
            this.event = data.data;
            this.event_ready = true;
          });

    }

  },


  methods:{

    onload: function(){
      console.log('onload')
    }


  }
}
</script>

<style scoped>

</style>
